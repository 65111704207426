import styled from "styled-components";

export const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  margin: 16px;
  height: 80%;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    width: auto;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: auto;
    flex-direction: column;
  }
`;

export const CardHeader = styled.h2`
  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const CardDescription = styled.p`
  color: #666;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

export const CardImages = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 4px;

  img {
    width: 100%;
    height: 50vh;
    border-radius: 4px;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.5);
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, auto);
  }
`;

export const SeeMoreLink = styled.div`
  margin-top: 10px;
  background: transparent;
  color: #007bff;
  padding: 8px 16px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: white;
  }
`;

