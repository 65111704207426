import styled from 'styled-components';

export const EventsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 10rem 2rem;
  min-height: 100vh
  }
`;
