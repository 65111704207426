import React from 'react';
import { Card, CardHeader, CardDescription, CardImages, SeeMoreLink, CardWrapper, ImageWrapper } from './EventCardStyles';
import { Link, useNavigate } from 'react-router-dom';

const EventCard = ({ event }) => {
  const navigate = useNavigate();

  const handleSeeMore = () => {
    navigate(`/event/${event.id}`);
  };

  return (
    <CardWrapper>
      <Card>
      <CardHeader>{event.title}</CardHeader>
      <CardDescription>{event.description}</CardDescription>
        <ImageWrapper>
        <CardImages>
        {event.images.map((image, index) => (
          <img src={image} alt={`${event.title} ${index + 1}`} key={index} />
        ))}
      </CardImages>
     </ImageWrapper>
      <SeeMoreLink  as={Link} to={`/events/${event.id}`}>See More</SeeMoreLink>
      </Card>
    </CardWrapper>
  );
};

export default EventCard;
