import styled from "styled-components";

export const ArticleContainer = styled.div`
  padding: 10rem 2rem;
  display: flex;
  min-height: 100vh
`;

export const Article = styled.div`
  padding: 10rem 2rem;
`;

export const ArticleTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const ArticleContent = styled.p`
  font-size: 16px;
`;
