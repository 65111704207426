import styled from "styled-components";

export const SectionContainer = styled.section`
  background-color: #E1E7ED;
  padding: 50px 0;
`;

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled.div`
  width: 150px;
  height: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoImage = styled.img`
  max-width: 70%;
  max-height: 70%;
`;

export const CleintHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
