export const catevents = [
  {
    text: "Inter Company Aerobics - 3rd August 2023",
    image1:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691496901/IMG_7440_twt23j.webp",
    image2:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691496908/IMG_7453_1_dtyaxi.webp",
    image3:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691496932/IMG_7473_hxpqul.webp",
    image4:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691497630/IMG_7422_ipeqrb.webp",
    image5:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691496917/IMG_7443_pnmfxz.webp",
  },
  {
    text: "Team Bonding Exercise - 28th July 2023",
    image1:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691484623/IMG_7196_t212tv.webp",
    image2:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691482794/IMG_7214_iaqekk.webp",
    image3:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691482793/IMG_7265_xxqf0u.webp",
    image4:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691482793/IMG_7198_cf4sq0.webp",
    image5:
      "https://res.cloudinary.com/dc4x5nvfy/image/upload/v1691484629/IMG_7169_1_cjuvyc.webp",
  },
  {
    text: "Leadership, Coaching and Mentoring Training - 27th Jan 2023",
    image1:
      "https://res.cloudinary.com/dayonuel/image/upload/v1674831790/new-image_19_afcync.jpg",
    image2:
      "https://res.cloudinary.com/dayonuel/image/upload/v1674831790/new-image_20_gginmr.jpg",
    image3:
      "https://res.cloudinary.com/dayonuel/image/upload/v1674834410/new-image_22_r4e008.jpg",
    image4:
      "https://res.cloudinary.com/dayonuel/image/upload/v1674834411/new-image_23_nih8cp.jpg",
    image5:
      "https://res.cloudinary.com/dayonuel/image/upload/v1674831785/new-image_16_sj9bp1.jpg",
  },
  //   {
  //     text: "",
  //     image1:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1674831786/new-image_12_a65lh2.jpg",
  //     image2:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1674831785/new-image_15_cjop8s.jpg",
  //     image3:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1674831787/new-image_17_gm9yza.jpg",
  //     image4:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1674831784/new-image_14_m8b2pp.jpg",
  //     image5:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1674831783/new-image_13_pp1taz.jpg",
  //   },
  {
    text: "10th Year Anniversary and End Of Year Party - 18th DEC 2022",
    image1:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672427196/1672427182029_immhzx.jpg",
    image2:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672427056/1672427045395_krqusi.jpg",
    image3:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672427149/1672427137779_qabs2s.jpg",
    image4:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672426886/1672426774332_uttlpb.jpg",
    image5:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672427109/1672427084092_uunpjj.jpg",
  },
  //   {
  //     text: "",
  //     image1:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672427010/1672426997386_x0ef4e.jpg",
  //     image2:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672426891/1672426825462_ukvhcs.jpg",
  //     image3:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672426896/1672426865829_vaqyrb.jpg",
  //     image4:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672426953/1672426943666_tny5rs.jpg",
  //     image5:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672426881/1672426741726_dsum2v.jpg",
  //   },
  {
    text: "Team Bonding Event - 29th OCT 2022",
    image1:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672309490/IMG20221029150412_vjgitw.jpg",
    image2:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672309478/IMG20221029150231_euhhsq.jpg",
    image3:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672309453/IMG20221029133850_yrp9rn.jpg",
    image4:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672309162/IMG20221029150133_toh0jw.jpg",
    image5:
      "https://res.cloudinary.com/dayonuel/image/upload/v1672309096/IMG20221029115334_wsxq4p.jpg",
  },
  //   {
  //     text: "",
  //     image1:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672309158/IMG20221029150145_wm6ido.jpg",
  //     image2:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672309126/IMG20221029132208_wlezxx.jpg",
  //     image3:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672309146/IMG20221029150039_syxsqf.jpg",
  //     image4:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672309128/IMG20221029132153_nlyofe.jpg",
  //     image5:
  //       "https://res.cloudinary.com/dayonuel/image/upload/v1672309141/IMG20221029132113_uzprfw.jpg",
  //   },
];
