import photo1 from "../../assets/images/photo1.jpg";
import photo2 from "../../assets/images/newPhoto2.webp";
import photo3 from "../../assets/images/photo3.jpg";
import photo4 from "../../assets/images/photo1.webp";

export const service = [
  {
    text: "Sales and Installation Of solar and inverter system",
    image: photo1,
  },
  {
    text: "Internet Service Provider and Telecommunications, Media & Entertainment Consultant",
    image: photo4,
  },
  {
    text: "Consultancy For Microfinance banks and Asset Management",
    image: photo2,
  },
  {
    text: "Sales and Installation Of CCTV, Access Control Doors, Intercom and ATM",
    image: photo3,
  },
];
