import React from "react";
import { CleintHead, Container, LogoContainer, LogoImage, SectionContainer } from "./ClientStyles";
import { clientPartnerData } from "./clientPartnerData";
import { clientPartnerData2 } from "./clientPartnerData";

const ClientAndPartners = () => {
  

  return (
    <SectionContainer>
      <CleintHead>
        <h3>Our Prime Customers</h3>
      </CleintHead>
    <Container>
      {clientPartnerData.map((item, index) => (
        <LogoContainer key={index}>
          <LogoImage src={item.logo} alt={item.name} />
        </LogoContainer>
      ))}
    </Container>
    <Container>
      {clientPartnerData2.map((item, index) => (
        <LogoContainer key={index}>
          <LogoImage src={item.logo} alt={item.name} />
        </LogoContainer>
      ))}
    </Container>
  </SectionContainer>
  );
};

export default ClientAndPartners;
