import React from 'react';
import { useParams } from 'react-router-dom';
import {
  ArticleContainer,
  ArticleTitle,
  ArticleContent,
  Article
} from './EventModalStyles';
import { events } from '../../Pages/EventPage/EventPageData';

const EventDetails = () => {
  const { id } = useParams();
  const event = events.find(event => event.id === parseInt(id));

  if (!event) {
    return <div>Event not found</div>;
  }

  return (
    <ArticleContainer>
      <Article>
      <ArticleTitle>{event.title}</ArticleTitle>
      <p>{event.article.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}</p>
      </Article>
    </ArticleContainer>
  );
};

export default EventDetails;

