import React from "react";
import { useState } from "react";
import basseyimg from "../../assets/images/basseyexit.webp";
import { ModalWrap } from "./ModalStyle.";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Modal = () => {
  const [isOpen, setIsOpen] = useState(false);

  if (sessionStorage.getItem("myFunctionExecuted") === "true") {
    return;
  }

  const toggleModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    sessionStorage.setItem("myFunctionExecuted", "true");
  };

  setTimeout(() => {
    toggleModal();
  }, 2000);

  return (
    <ModalWrap>
      {isOpen && (
        <div className="modal">
          <section>
            <div className="modal-body">
              <h1 onClick={closeModal}>
                <AiOutlineCloseCircle />
              </h1>
              <div>
                <img src={basseyimg} alt="" />
                <p>
                  <strong>Denukan Network Limited</strong> With heavy hearts, commemorates the one-year anniversary of the passing of our esteemed Head of Accounts, Michael Elias Nseabasi Bassey. On May 5, 2023, Michael left us after a brief illness, leaving behind a legacy of dedication and hard work. A cherished member of our team for over 15 years, Michael's absence is deeply felt by colleagues and clients alike.
                  <br />
                  <br />
                  On this solemn occasion, Denukan Network Limited extends heartfelt condolences to Michael's family and friends. He will forever remain an integral part of our team, deeply missed and fondly remembered as a dear friend to all who had the privilege of knowing him.
                  <br />
                </p>
              </div>
            </div>
          </section>
        </div>
      )}
    </ModalWrap>
  );
};

export default Modal;
