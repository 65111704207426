import styled from "styled-components";

export const ModalWrap = styled.div`
  h1 {
    font-size: 30px;
    text-align: end;
    margin: 0;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999999999999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slide-in 0.5s ease-in;

    .modal-body {
      background-color: white;
      position: relative;
      /* min-width: 320px; */
      max-width: 650px;
      padding: 2rem;
      overflow-y: scroll;
      width: 90vw;
      border-radius: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      height: 70vh;
    }
    img {
      width: 50%;
      height: 50%;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }

  @keyframes slide-in {
    0% {
      transform: translatex(-100%);
    }

    100% {
      transform: translatex(0);
    }
  }
`;
