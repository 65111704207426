// import React, { useState, useEffect } from "react";
import { PeopleWrapper } from "./PeopleStyles";

import { managementData, teamLeadsData } from "./PeopleData";
// import axios from "axios";

const People = () => {
  // const [csvFileData, setCsvFileData] = useState([]);

  // const url = "https://jsonplaceholder.typicode.com/users";

  // useEffect(() => {
  //    axios
  //       .get(url)
  //       .then(function (response) {
  //          setCsvFileData(response.data);
  //       })
  //       .catch(function (error) {
  //          console.log(error);
  //       })
  //       .finally(function () {
  //          console.log("executed");
  //       });
  // }, []);

  // const csvString = [
  //    ...csvFileData.map((item) => [
  //       item.id,
  //       item.name,
  //       item.username,
  //       item.email,
  //    ]),
  // ];

  // function download_csv_file() {
  //    var csv = "ID,NAME,USERNAME,EMAIL\n";
  //    csvString.forEach(function (row) {
  //       csv += row.join(",");
  //       csv += "\n";
  //    });

  //    let hiddenElement = document.createElement("a");
  //    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);

  //    //provide the name for the CSV file to be downloaded
  //    hiddenElement.download = "My DB Data.csv";
  //    hiddenElement.click();
  // }

  return (
    <PeopleWrapper>
      {/* <h1>People</h1>
         <button onClick={download_csv_file}> Download CSV </button> */}

      <div className="container">
        <div className="heading-title">
          <h3>Meet Our Team </h3>
        </div>

        <p>
          The Company activities are pioneer by the MD/CEO, Mr. Ayodeji Odunukan
          who has over 15 years in Information Technology and brings his wealth
          of experience to the Company. The Team has an accumulation of over 18
          years working experience in various industries and sectors of the
          economy. Finally, the Head of HR/Admin Mrs. Ololade Odunukan who is a
          Licensed HR Practitioner. We are a company for excellence and will
          expect the best as you join our family.
        </p>
        <br />
        <div className="flex">
          {managementData.map((i, idx) => (
            <div className="col-md-4 col-sm-4" key={idx}>
              <div className="team-member">
                <div className="team-img">
                  <img src={i.img} alt="Managing Director" />
                </div>
              </div>
              <div className="team-title">
                <h4>{i.name}</h4>
                <p>{i.title}</p>
              </div>
            </div>
          ))}
        </div>
        <br />
        <h3 className="cent">Team Leads</h3>
        <br />

        <div className="flex4">
          {teamLeadsData.map((i, idx) => (
            <div className="col-md-4 col-sm-4" key={idx}>
              <div className="team-member">
                <div className="team-img">
                  <img src={i.img} alt="Kayode  Ariyo" />
                </div>
              </div>
              <div className="team-title">
                <h4>{i.name}</h4>
                <p>{i.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PeopleWrapper>
  );
};

export default People;
