import styled from "styled-components";

export const HomeWrapper = styled.div`
  width: 90%;
  margin: 6rem auto;
  max-width: 1470px;
  padding: 6rem 0 0 0;
  position: relative;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70vh;

    .first-child {
      width: 100%;

      h1 {
        line-height: 1.25;
        font-weight: 500;
      }
      h2 {
        margin-top: -2rem;
        font-weight: 500;
      }
    }
  }

  h1 {
    font-size: clamp(3rem, 4vw, 5.1rem);
    color: ${({ theme }) => theme.colors?.side};
    font-weight: 600;
    margin-bottom: 4rem;
  }

  h2 {
    font-size: clamp(1rem, 2.8vw, 1.3rem);
    color: ${({ theme }) => theme.colors?.side};
  }

  .imgWrapper {
    width: 100%;
    max-width: 740px;
    display: flex;
    justify-content: end;

    img {
      width: 110%;
      /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2); */
    }
  }

  @media screen and (max-width: 790px) {
    width: 98%;
    margin: auto;

    .top {
      display: block;
      height: 58vh;
    }

    h1 {
      margin-top: 3rem;
      margin-bottom: 2.5rem;
    }
    /* padding: 7rem 0px 3rem 55px; */

    .imgWrapper {
      margin: auto;
      width: 100%;
      text-align: center;

      img {
        transform: translateY(4rem);
        width: 100%;
      }
    }
  }

  /* @keyframes spin {
      from {
         transform: rotate(0deg);
      }
      to {
         transform: rotate(360deg);
      }
   } */
`;
