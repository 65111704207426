// clientPartnerData.js
import logo2 from "../../assets/images/moniepoint.png"
import logo3 from "../../assets/images/bestsr.jpeg"
import logo4 from "../../assets/images/raven.png"
import logo5 from "../../assets/images/IDIIGBA.png"
import logo6 from "../../assets/images/dorbenlogo (1).png"
import logo7 from "../../assets/images/awacash.jpeg"
import logo8 from "../../assets/images/katsu.png"
import logo9 from "../../assets/images/eyowo.png"
import logo02 from "../../assets/images/sebastian.jpeg"
import logo03 from "../../assets/images/rufus giwa.jpeg"
import logo04 from "../../assets/images/dealmakers.jpeg"
import logo05 from "../../assets/images/gateway.png"
import logo06 from "../../assets/images/Astral.png"
import logo07 from "../../assets/images/Ibolo.png"
import logo08 from "../../assets/images/Dornet.png"
export const clientPartnerData = [
  {
    name: 'Moniepoint',
    logo: logo2,
  },
  {
    name: 'Beststar Microfinance',
    logo: logo3,
  },
  {
    name: 'Raven Bank',
    logo: logo4,
  },
  {
    name: 'Idi-Igba',
    logo: logo5,
  },
  {
    name: 'Dorben',
    logo: logo6,
  },
  {
    name: 'Awacash',
    logo: logo7,
  },
  {
    name: 'Katsu',
    logo: logo8,
  },
  {
    name: 'eyoowo',
    logo: logo9,
  },
  // Add more clients/partners as needed
];

export const clientPartnerData2 = [
  {
    name: 'sebastian',
    logo: logo02,
  },
  {
    name: 'rufus giwa',
    logo: logo03,
  },
  {
    name: 'Deal Makers',
    logo: logo04,
  },
  {
    name: 'gateway',
    logo: logo05,
  },
  {
    name: 'Astral',
    logo: logo06,
  },
  {
    name: 'Ibolo',
    logo: logo07,
  },
  {
    name: 'Dornet',
    logo: logo08,
  },
  {
    name: 'eyoowo',
    logo: logo9,
  },
  // Add more clients/partners as needed
];
