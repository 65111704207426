import logo2 from "../../assets/images/DSC_0492.jpg"
import logo3 from "../../assets/images/DSC_0550.jpg"
import logo4 from "../../assets/images/DSC_0590.jpg"
import logo5 from "../../assets/images/DSC_02.webp"
import logo6 from "../../assets/images/DSC_03.webp"
import logo7 from "../../assets/images/DSC_04.webp"
import logo8 from "../../assets/images/DSC_05.webp"
import logo9 from "../../assets/images/DSC_06.webp"


export const events = [
  {
    id: 1,
    title: 'Denukan Motorcycle Distribution',
    description: 'Denukan Network Limited distributes brand new Motorcycles to the indigenes of Ondo and Oyo',
    article: 'In a heartwarming gesture that has sent ripples of excitement through the communities, Denukan Network Limited has distributed brand-new motorcycles to the indigenes of Ondo and Oyo states. This act of generosity is more than just a donation; it is a beacon of hope and progress for the recipients and their families. \n Each motorcycle symbolizes more than just a vehicle; it represents freedom, empowerment, and a brighter future. The recipients, many of whom are young entrepreneurs and hardworking individuals, now have the means to expand their businesses, reach new markets, and connect with vital resources previously beyond their reach.\n One of the lucky beneficiaries, could hardly contain his excitement. as it is not just a gift; it\'s a lifeline. It will enable recipients to transport goods more efficiently and reach customers. \n The impact of this donation extends beyond individual stories of joy. It signifies a broader commitment to community development and economic empowerment. Denukan Network Limited\’s visionary initiative sets a precedent for corporate responsibility and community engagement, inspiring other organizations to contribute meaningfully to societal progress. \n As the motorcycles rev into action across the dusty trails and bustling streets of Ondo and Oyo, they carry with them the promise of a transformed tomorrow. Denukan Network Limited has not only given motorcycles; they have ignited a movement towards prosperity and self-reliance \n This remarkable act is a testament to the power of giving and the profound impact it can have on communities. It\'s a story of hope,of change, and most importantly, a story that will be told for generations to come.',
    images: [logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9]
  },
  // {
  //   id: 2,
  //   title: 'Event 2',
  //   description: 'Description for event 2.',
  //   images: ['https://via.placeholder.com/100', 'https://via.placeholder.com/100']
  // },
  // More events...
];