import React from 'react';
import { EventsContainer } from './EventPageStyles';
import { events } from './EventPageData';
import EventCard from '../../Components/EventCard/EventCard';
import EventModal from '../../Components/EventModal/EventModal';
import { useState } from 'react';


const EventsPage = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleSeeMore = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  return (
    <div>
      <EventsContainer>
        {events.map(event => (
          <EventCard key={event.id} event={event} onSeeMore={handleSeeMore} />
        ))}
      </EventsContainer>
    </div>
  );
};

export default EventsPage;

