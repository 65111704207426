import { HomeWrapper } from "./HomeStyles";

import mccc from "../../assets/images/micheal header.webp";
import About from "../../Components/About/About";
import Offer from "../../Components/Offer/Offer";
import BlogSlider from "../../Components/BlogSlider/BlogSlider";
import ClientAndPartners from "../../Components/ClientAndPartners/Client";

const Home = () => {
  return (
    <HomeWrapper>
      <div className="top">
        <div className="first-child">
          <h1>
            Denukan Network
            <br /> Limited.
          </h1>
          <h2>Integrating tech-centered solutions into everyday life.</h2>
        </div>
        <div className="imgWrapper">
          <img src={mccc} alt="illustration for core tech" />
        </div>
      </div>
      <About />
      <ClientAndPartners />
      <Offer />
      <br />
      <br />
      <BlogSlider />
      <br />
    </HomeWrapper>
  );
};

export default Home;
