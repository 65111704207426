import styled from "styled-components";

export const SubWrap = styled.div`
  margin-top: 5rem;

  ul {
    padding-inline: 2rem;
    color: #fff;

    li {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
`;
